import React from 'react';

import SimpleLayout from '../components/layouts/simple-layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <SimpleLayout>
    <SEO title="404: Not found" />
    <section className="text-center my-5">
      <span className="text-gray-300 font-sans font-bold text-6xl md:text-huge">404</span>
      <h1 className="text-xl md:text-4xl text-gray-700 text-left md:text-center mt-6">
        The page you're looking for doesn't exist.
      </h1>
      <h2 className="text-gray-500 text-sm md:text-xl my-3 text-left md:text-center">
        You might've mistyped the address or the page might've been moved.
      </h2>
    </section>
  </SimpleLayout>
);

export default NotFoundPage;
